import './bootstrap-front';
import Alpine from "alpinejs";
import fetchX from "../../utils/fetchSetup";
import { AlpineLoadingStore } from "../../utils/alpine/loading";

const productContainer = document.querySelector('#wspf-container-products');
const prodsByCatContainer = document.querySelector('#wspf-container-prodsbycat');

interface IStore {
  loading: AlpineLoadingStore;
  productEngine: AlpineProductEngine;
}

interface ParamGetProducts {
  search: string;
  page: string;
  perPage: string;
  orderBy: string;
  order: string;
  category: string;
}

interface AlpineProductEngine {
  loadingStore: AlpineLoadingStore;
  allFilters: object;
  search: string;

  getProducts(param: ParamGetProducts): Promise<void>;
}

Alpine.store('productEngine', {
  loadingStore: Alpine.store('loading') as AlpineLoadingStore,
  allFilters: {},
  search: '',
  async getProducts(
    {
      search = '',
      page = '1',
      perPage = '10',
      orderBy = 'post_date',
      order = 'desc',
    }: ParamGetProducts,
  ) {
    this.loadingStore.show();

    const formDataEntries = {
      'action': 'wspf_get_products',
      'wspf-nonce': wspf.nonce,
      search,
      page,
      perPage,
      orderBy,
      order,
      'allFilters': JSON.stringify(this.allFilters),
    };

    const data: FormData = new FormData();
    for (const [key, value] of Object.entries(formDataEntries)) {
      data.append(key, value);
    }
    await fetchX({
      url: wspf.ajaxUrl,
      callback: ({html}: {html: string}) => {
        Alpine.morph(productContainer, html, {});
        productContainer.scrollIntoView({behavior: "smooth"});
        this.loadingStore.hide();
      },
      options: {
        method: 'POST',
        body: data,
      },
    });
  },
  async getProductsByCat(
    {
      page = '1',
      perPage = '8',
      orderBy = 'post_date',
      order = 'desc',
      category = '',
    }: ParamGetProducts,
  ) {
    this.loadingStore.show();

    const formDataEntries = {
      'action': 'wspf_get_prodsbycat',
      'wspf-nonce': wspf.nonce,
      page,
      perPage,
      orderBy,
      order,
      category,
    };

    const data: FormData = new FormData();
    for (const [key, value] of Object.entries(formDataEntries)) {
      data.append(key, value);
    }
    await fetchX({
      url: wspf.ajaxUrl,
      callback: ({html}: {html: string}) => {
        Alpine.morph(prodsByCatContainer, html, {});
        prodsByCatContainer.scrollIntoView({behavior: "smooth"});
        this.loadingStore.hide();
      },
      options: {
        method: 'POST',
        body: data,
      },
    });
  },
} as AlpineProductEngine);

interface AlpineProductFilters {
  filterId: string;

  init(): void;

  filter: object;
  $store: IStore;
}

Alpine.data('productFilters', (filterId: string) => (
  {
    filterId,
    init() {
      this.$store.productEngine.allFilters[filterId] = [];
    },
    filter: {
      '@click'() {
        const value = this.$el.value;
        this.$store.els.hideAll();
        if (this.$store.productEngine.allFilters[filterId].includes(value)) {
          this.$store.productEngine.allFilters[filterId] = this.$store.productEngine.allFilters[filterId].filter((v) => {
            return v !== value;
          });
        } else {
          this.$store.productEngine.allFilters[filterId] = [...this.$store.productEngine.allFilters[filterId], value];
        }
        this.$store.productEngine.getProducts(<ParamGetProducts>{
          search: this.$store.productEngine.search,
        });
      },
    },
  } as AlpineProductFilters
));

interface AlpinePagination {
  init(this: AlpinePagination): void;

  currentPage: string;
  paginationLink: object;
  $store: IStore;
}

Alpine.data('pagination', (type = 'prods') => (
  {
    currentPage: '1',
    paginationLink: {
      '@click'() {
        if (this.$el.dataset.page === this.currentPage) return;
        this.currentPage = this.$el.dataset.page;

        const params = {
          search: this.$store.productEngine.search,
          page: this.currentPage,
        } as ParamGetProducts;

        if (type === 'prods') {
          this.$store.productEngine.getProducts(params);
        } else {
          this.$store.productEngine.getProductsByCat({
            ...params,
            category: type,
          });
        }
      },
    },
  } as AlpinePagination
));

interface AlpineSorter {
  currentSort: string;
  currentOrder: string;
  sortBy: object;
  sortIconUp: object;
  sortIconDown: object;
  $store: IStore
}

Alpine.data('sorter', (type = 'prods') => ({
  currentSort: '',
  currentOrder: '',
  sortBy: {
    ['@click']() {
      const fieldName = this.$el.dataset.fieldName;
      this.$store.els.hideAll();
      if (this.currentSort === fieldName) {
        this.currentOrder = this.currentOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.currentSort = fieldName;
        this.currentOrder = 'asc';
      }

      const params = {
        orderBy: this.currentSort,
        order: this.currentOrder,
      } as ParamGetProducts;

      if (type === 'prods') {
        this.$store.productEngine.getProducts(params);
      } else {
        this.$store.productEngine.getProductsByCat({
          ...params,
          category: type,
        });
      }
    },
    [':class']() {
      const fieldName = this.$el.dataset.fieldName;
      return {
        'wspf-sort-active': this.currentSort === fieldName,
        'wspf-sort-asc': this.currentSort === fieldName && this.currentOrder === 'asc',
        'wspf-sort-desc': this.currentSort === fieldName && this.currentOrder === 'desc',
      }
    },
  },
  sortIconUp: {
    ['x-show']() {
      const fieldName = this.$el.parentElement.dataset.fieldName;
      return this.currentSort === fieldName && this.currentOrder === 'asc'
    },
  },
  sortIconDown: {
    ['x-show']() {
      const fieldName = this.$el.parentElement.dataset.fieldName;
      return this.currentSort === fieldName && this.currentOrder === 'desc'
    },
  },
} as AlpineSorter));

interface AlpineSearcher {
  init(): void;

  search: string;
  month: string;
  isSearching: number | null;
  searchInput: object;
  monthInput: object;
}

const now = new Date();
const resultsContainer = document.querySelector('.wspf-results-container');
const productSearcherResults = document.querySelector('#wspf-searcher-results');

Alpine.data('searcher', () => ({
  search: '',
  month: (now.getMonth() + 1 + '').padStart(2, '0') + '-' + now.getFullYear(),
  isSearching: null,
  init() {
    this.$watch('search', (value: string) => {
      this.getProducts({search: value} as ParamGetProducts);
    });
    this.$watch('month', () => {
      this.getProducts({search: this.search} as ParamGetProducts);
    });
  },
  searchInput: {
    'x-model': 'search',
  },
  monthInput: {
    'x-model': 'month',
  },
  getProducts({search = '', page = '1', perPage = '6'}) {
    if (this.isSearching) {
      clearTimeout(this.isSearching);
      this.isSearching = null;
    }

    if (search.length < 3) {
      productSearcherResults.innerHTML = '';
      resultsContainer.classList.add('wspf-hidden');
      return;
    }

    this.isSearching = setTimeout(async () => {
      const formDataEntries = {
        'action': 'wspf_get_searched_products',
        'wspf-nonce': wspf.nonce,
        'search': search,
        'page': page,
        'perPage': perPage,
        'calendar': this.month,
      };

      const data: FormData = new FormData();
      for (const [key, value] of Object.entries(formDataEntries)) {
        data.append(key, value);
      }
      await fetchX({
        url: wspf.ajaxUrl,
        callback: ({html}: {html: string}) => {
          Alpine.morph(productSearcherResults, html, {});
          resultsContainer.classList.remove('wspf-hidden');
        },
        options: {
          method: 'POST',
          body: data,
        },
      });
    }, 500);
  },
} as AlpineSearcher));

interface AlpineEls {
  filters: string | HTMLDivElement;
  sort: string | HTMLDivElement;
}

Alpine.store("els", {
  "filters": "wspf-cont-filters",
  "sort": "wspf-cont-sort",
  hideAll() {
    if (window.matchMedia('(max-width: 768px)').matches) {
      Object.entries(this).forEach(([, el]: [string, string | HTMLDivElement]) => {
        if (el instanceof HTMLDivElement) {
          el.classList.add('!wspf-hidden');
        }
      });
    }
  },
} as AlpineEls);

interface AlpineNavbarBottom {
  active: string | null;
  els: object;

  init(): void;

  btn: object;
}

Alpine.data('navbarBottom', () => ({
  active: null,
  init() {
    for (const [key, id] of Object.entries(this.$store.els)) {
      if (key === 'hideAll') continue;
      this.$store.els[key] = document.querySelector(`#${id}`);
      if (window.matchMedia('(max-width: 768px)').matches) {
        this.$store.els[key]?.classList?.add('!wspf-hidden', '!wspf-flex');
      }
    }
  },
  btn: {
    ['@click']() {
      const type = this.$el.dataset.type;
      if (this.active === type) {
        this.$store.els[type].classList.toggle('!wspf-hidden');
        this.active = null;
      } else {
        if (this.active) {
          this.$store.els[this.active].classList.add('!wspf-hidden');
        }
        this.$store.els[type].classList.remove('!wspf-hidden');
        this.active = type;
      }

    },
    [':class']() {
      return {
        'wspf-active': this.active === this.$el.dataset.type,
      }
    },
  },
} as AlpineNavbarBottom));

interface AlpineTitleFilter {
  btnFilter: string;

  init(): void;

  close: object;
}

Alpine.data('titleFilter', () => ({
  btnFilter: '[data-type="filters"]',
  init() {
    this.btnFilter = document.querySelector(this.btnFilter);
  },
  close: {
    ['@click']() {
      this.btnFilter.click();
    },
  },
} as AlpineTitleFilter));

Alpine.start();